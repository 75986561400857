import { FC, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { QRCodeSVG } from 'qrcode.react';
import * as speakeasy from 'speakeasy';

import { Box, Button, Dialog, DialogContent, DialogTitle, IconButton, Link, Stack, Typography } from '@mui/material';

import FormInput from 'components/common/Form/Input';
import CloseIcon from 'components/common/svg/close';

import { createTwoFactorAuthenticationResolver } from 'config/resolvers';
import useTranslates from 'utils/translate';
import useToaster from 'hooks/useToaster';

import Copy from 'components/common/Copy';
import useCurrentUser from 'store/currentUser';
import useDialog from 'store/dialog';
import useSockets from 'hooks/useSockets';
import AuthService from 'api/auth/AuthService';
import { userStorage } from 'store/storage';
import PlayMarket from 'components/common/icons/PlayMarket';
import AppStore from 'components/common/icons/AppStore';

interface TwoFactorAuthenticationProps {
  open: boolean;
  onClose: () => void;
}

const TwoFactorAuthentication: FC<TwoFactorAuthenticationProps> = ({ open, onClose }) => {
  const notify = useToaster();
  const [dialog] = useDialog();
  const { translate } = useTranslates();
  const [, currentUserActions] = useCurrentUser();
  const socket = useSockets();
  const { isTwoFactorAuthenticationEnabled, username } = userStorage.get();

  const secret = useMemo(
    () => speakeasy.generateSecret({ length: 16, name: `${window.location.host}:${username}` }),
    []
  );

  const isOpenedFromProfile = dialog.params.source === 'profile';
  const willEnableTwoFactorAuthentication = !isTwoFactorAuthenticationEnabled;
  const willDisableTwoFactorAuthentication = isTwoFactorAuthenticationEnabled && isOpenedFromProfile;
  const willVerifyTwoFactorAuthentication = isTwoFactorAuthenticationEnabled && !isOpenedFromProfile;

  const isPasswordRequired = willEnableTwoFactorAuthentication || willDisableTwoFactorAuthentication;

  const { control, handleSubmit } = useForm({
    resolver: createTwoFactorAuthenticationResolver(isPasswordRequired),
  });

  const onVerify = async (data) => {
    const player =
      willEnableTwoFactorAuthentication || willDisableTwoFactorAuthentication
        ? await AuthService.enableTwoFactorAuthentication({
            ...(willEnableTwoFactorAuthentication && { secret: secret.base32 }),
            enabled: !isTwoFactorAuthenticationEnabled,
            ...data,
          })
        : await AuthService.verifyTwoFactorAuthentication(data);

    currentUserActions.set(player);
    socket.connect();
    onClose();
    if (willEnableTwoFactorAuthentication || willDisableTwoFactorAuthentication) {
      notify({
        message: translate(
          willEnableTwoFactorAuthentication ? '2fa_successfully_activated' : '2fa_successfully_deactivated'
        ),
      });
    }
  };

  return (
    <Dialog keepMounted open={open} onClose={() => onClose()}>
      <DialogTitle>
        <Typography fontSize={18} fontWeight={600}>
          {translate('2fa_title')}
        </Typography>
        <IconButton onClick={() => onClose()}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Stack gap={3} mb={4} key={open ? 1 : 2}>
          <Typography textAlign="center">
            {translate(
              willEnableTwoFactorAuthentication
                ? '2fa_description_enable'
                : willDisableTwoFactorAuthentication
                ? '2fa_description_disable'
                : '2fa_description'
            )}
          </Typography>
          {willEnableTwoFactorAuthentication && (
            <Box textAlign="center">
              <QRCodeSVG value={secret.otpauth_url} />
              <Copy sx={{ marginTop: '8px', justifyContent: 'center' }} text={secret.base32} />
              <Box display="flex" justifyContent="space-around" marginTop="-16px">
                <Link href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2">
                  <PlayMarket sx={{ width: '130px', height: '100px' }} />
                </Link>
                <Link href="https://apps.apple.com/us/app/google-authenticator/id388497605">
                  <AppStore sx={{ width: '130px', height: '100px' }} />
                </Link>
              </Box>
            </Box>
          )}
          <Box
            gap={2}
            display="flex"
            flexDirection="column"
            {...(willEnableTwoFactorAuthentication && { marginTop: "-32px" })}
          >

          {(willVerifyTwoFactorAuthentication ||
            willEnableTwoFactorAuthentication ||
            willDisableTwoFactorAuthentication) && (
            <FormInput control={control} name="code" placeholder={translate('verify_2fa_code')} />
          )}
          {isPasswordRequired && (
            <FormInput control={control} name="password" type="password" placeholder={translate('password')} />
          )}
          </Box>

        </Stack>
        <Button fullWidth variant="contained" onClick={handleSubmit(onVerify)}>
          {translate(
            willEnableTwoFactorAuthentication
              ? 'enable_2fa'
              : willDisableTwoFactorAuthentication
              ? 'disable_2fa'
              : 'verify_2fa'
          )}
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default TwoFactorAuthentication;
